import React, { useRef, useEffect, useState } from "react"
import { connect } from "react-redux"
import fadeToggle from "../../utils/fadeToggle"
import HeroVideo from "./heroVideo"
import videoPosterDesktop from "./videos/home-video-poster.webp"
import ScrollMarker from "../ScrollMarker"

const HeroHome = props => {
  const [reveal, setReveal] = useState(false)
  const [heroHeight, setHeroHeight] = useState(null)
  const sectionHero = useRef(null)
  const fadeTriggerRef = useRef(null)
  const heroContentRef = useRef(null)
  const {
    initialSiteLoad,
    transitionStatus,
    slogan,
    subtitle,
    bgColour,
  } = props

  useEffect(() => {
    // Signify a time for our hero content to animate in
    if (transitionStatus === "entered") {
      setTimeout(
        () => {
          setReveal(true)
        },
        initialSiteLoad ? 2500 : 1500
      )
    }
  }, [transitionStatus])

  useEffect(() => {
    // Fade out content on scroll
    fadeToggle(heroContentRef.current, fadeTriggerRef.current)
  }, [])

  useEffect(() => {
    // on component mount get the space between hero and bottom of viewport
    setHeroHeight(window.outerHeight - sectionHero.current.clientHeight + 100)

    setTimeout(() => {
      // Remove this height (which reveals content below with a transition)
      setHeroHeight(null)
    }, 1500)
  }, [])

  return (
    <>
      <section
        ref={sectionHero}
        className="section section--hero section--hero-home"
        style={{
          paddingBottom: heroHeight, // use of this with transition creates the sliding content animation
          transition:
            heroHeight === null
              ? `padding-bottom 1.5s cubic-bezier(0.645, 0.045, 0.355, 1.000)`
              : null,
        }}
      >
        <div ref={heroContentRef} className={`${reveal ? `reveal` : ``}`}>
          <div className="container-fluid">
            <h1
              className="intro-title intro-title--home intro-title--large"
              dangerouslySetInnerHTML={{ __html: slogan }}
              style={{
                animationDelay: `1.5s`,
              }}
            />
            <div
              className="intro-subtitle"
              style={{
                animationDelay: `2s`,
              }}
            >
              {subtitle != null ? <h2>{subtitle}</h2> : null}
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <ScrollMarker reveal={reveal} variant="default" />
        </div>
      </section>

      <section
        className={`section section--full-video`}
        style={{
          backgroundColor: bgColour,
          backgroundImage: `url(${videoPosterDesktop})`,
        }}
      >
        <HeroVideo reveal={reveal} />
      </section>
    </>
  )
}

export default connect(
  state => ({
    initialSiteLoad: state.initialSiteLoad.initialSiteLoad,
  }),
  null
)(HeroHome)
