import React, { useEffect } from "react"

import { connect } from "react-redux"
import { graphql } from "gatsby"
import { TransitionState } from "gatsby-plugin-transition-link"

import { setBreadcrumbs } from "../state/breadcrumbs"
import { toggleBackground } from "../state/background"
import { toggleStickyHeaderColour } from "../state/header"

import SiteLayout from "../components/SiteLayout"
import Seo from "../components/Seo"
import HeroHome from "../components/Hero/heroHome"
import Clients from "../components/Clients"
import Services from "../components/Services"
import Chevron from "../components/Symbols/chevron"
import PageLink from "../components/PageLink"
import FeaturedWork from "../components/FeaturedWork"
import Reveal from "../components/Waypoints"

const Home = props => {
  const {
    dispatch,
    location,
    transitionStatus,
    data: { allWpPage },
  } = props

  const {
    title,
    slug,
    seo,
    hero,
    homeContent,
    links,
    clients,
    featuredWork,
  } = allWpPage.edges[0].node

  useEffect(() => {
    // update breadcrumbs state
    dispatch(
      setBreadcrumbs({
        current: {
          title: title,
          path: slug,
        },
      })
    )
  }, [])

  useEffect(() => {
    // if first load change bg colour instantly,
    // else from internal link, delay for swipe transition
    if (transitionStatus === "exited") {
      setTimeout(() => {
        dispatch(toggleBackground("black"))
        dispatch(toggleStickyHeaderColour("black"))
      }, 750)
    } else {
      // change bg and sticky header colour instantly,
      dispatch(toggleBackground("black"))
      setTimeout(() => {
        dispatch(toggleStickyHeaderColour("black"))
      }, 1000)
    }
  }, [])

  return (
    <SiteLayout location={location}>
      <Seo
        seoTitle={seo.title ? seo.title : title}
        seoDesc={seo.metaDesc ? seo.metaDesc : null}
        ogTitle={seo.opengraphTitle ? seo.opengraphTitle : seo.title}
        ogDesc={
          seo.opengraphDescription ? seo.opengraphDescription : seo.metaDesc
        }
        ogImage={
          seo.opengraphImage && seo.opengraphImage.localFile
            ? seo.opengraphImage.localFile.url
            : null
        }
      />

      <TransitionState>
        {({ transitionStatus }) => {
          return (
            <HeroHome
              slogan={hero.heroSlogan}
              subtitle={hero.heroSubtitle}
              bgColour="#3b3e49"
              transitionStatus={transitionStatus}
            />
          )
        }}
      </TransitionState>

      <main id="main" className="main-content main-content--half">
        {homeContent?.intro && (
          <section className="section section--no-space-bottom">
            <Reveal delay="0.25">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-bp1-12 col-bp4-11">
                    <h2 className="intro-title intro-title--large intro-title--dark">
                      {homeContent.intro}
                    </h2>
                  </div>
                </div>
              </div>
            </Reveal>
          </section>
        )}

        {links?.links && (
          <section className="section section--no-space-bottom">
            <Reveal delay="0.25">
              <div className="container-fluid">
                <div className="row">
                  {links?.links?.map((link, i) => (
                    <div
                      key={`${link.pageLink.uri}-${i}`}
                      className="col-bp1-12 col-bp2-4"
                    >
                      <PageLink
                        className="button button--group"
                        url={link.pageLink.uri}
                        dispatch={dispatch}
                        colour={link.transitionColour}
                        cursor="alt"
                        menuLink={false}
                      >
                        <span>{link.pageLink.title}</span>
                        <Chevron />
                      </PageLink>
                    </div>
                  ))}
                </div>
              </div>
            </Reveal>
          </section>
        )}

        {featuredWork && (
          <FeaturedWork featuredWork={featuredWork} count={3} home />
        )}

        <section className="section">
          <Clients clients={clients} />
        </section>

        <Services dispatch={dispatch} />
      </main>
    </SiteLayout>
  )
}

export default connect(
  state => ({
    breadcrumbs: state.breadcrumbs.breadcrumbs,
  }),
  null
)(Home)

export const pageQuery = graphql`
  query GET_HOME($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          content
          slug
          seo {
            title
            metaDesc
            opengraphTitle
            opengraphDescription
            opengraphImage {
              localFile {
                url
              }
            }
          }
          hero {
            heroSlogan
            heroSubtitle
          }
          homeContent {
            intro
          }
          clients {
            clients {
              clientName
              clientImage
            }
          }
          links {
            links {
              pageLink {
                ... on WpPage {
                  id
                  uri
                  title
                }
              }
              transitionColour
            }
          }
          featuredWork {
            featuredWorkItems {
              featuredWorkItem {
                ... on WpProject {
                  id
                  slug
                  uri
                  title
                  projectFields {
                    projectLogoLightMode {
                      altText
                      sourceUrl
                    }
                    projectPreviewImage {
                      altText
                      sourceUrl
                      mimeType
                      localFile {
                        childImageSharp {
                          fluid(
                            maxHeight: 480
                            maxWidth: 680
                            quality: 85
                            cropFocus: CENTER
                          ) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                    projectShortDesc
                    projectSubnav {
                      projectSubnavItem {
                        ... on WpPage {
                          id
                          title
                          uri
                          parent {
                            ... on WpPage {
                              slug
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
