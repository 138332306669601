import React, { useRef, useEffect } from "react"
import Player from "@vimeo/player"

const HeroVideo = props => {
  const { reveal } = props
  const vid = useRef(null)

  useEffect(() => {
    // Play Vimeo vid once loading screen is hidden or
    // when the page transition ends when entering from another page
    const player = new Player(vid.current)

    if (reveal && vid !== null && player !== null) {
      player
        .play()
        .then(() => {
          // console.log('video played')
        })
        .catch(error => {
          console.log(error)
        })
    }

    // when unmounting stop vid
    return () => {
      if (vid !== null && player !== null) {
        player.pause()
      }
    }
  }, [reveal])

  return (
    <div aria-hidden={true} className={`hero__video ${reveal ? `reveal` : ``}`}>
      <iframe
        ref={vid}
        src="https://player.vimeo.com/video/852265769?controls=0&muted=1&loop=1"
        width="1920"
        height="1080"
        allowFullScreen
        title="Curved lines animation"
      ></iframe>
    </div>
  )
}

export default HeroVideo
